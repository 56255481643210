import React from 'react';
import { Link } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import { PrismicRichText } from '@prismicio/react'

const Text = ({primary}) => {
  return (
    <div className="w-full py-12" data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
      <div className="w-11/12 mx-auto max-w-screen-2xl">
        <div className="flex flex-col-reverse md:flex-row justify-between gap-6 md:gap-24">
          <div className="w-full md:w-6/12">
            <div className="font-sans prose prose-lg prose-h2:font-brand prose-h3:font-brand leading-normal">
              <PrismicRichText linkResolver={linkResolver} field={primary.text1.richText} />
            </div>
            {primary.button1 ? <div className="rounded-lg font-sans text-xl font-black text-white bg-blue mt-12 py-3 px-6 inline-block">
              <Link to={linkResolver(primary.page_link1)}>{primary.button1}</Link>
            </div> : '' }
          </div>
          <div className="w-full md:w-6/12">
            {primary.heading1 !== null ? <h2 className="font-brand leading-none font-black text-4xl md:text-5xl text-blue mb-6">
              {primary.heading1}
            </h2> : 
            <div className="font-brand leading-none font-black text-4xl md:text-5xl text-blue mb-6">
              <PrismicRichText field={primary.heading.richText} />
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Text;
