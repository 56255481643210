import * as React from "react"
import { graphql } from "gatsby"
import { linkResolver } from '../../utils/link_resolver'
import Seo from '../../components/seo'
import Footer from '../../components/footer'
import Header from "../../components/header"
import Heading from "../../components/heading"
import Text from "../../components/text"

const ThanksPage = ({data}) => {
  const entry = data.prismicThanksPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header logo='cuddon' />
      {entry.data.body.map((slice) => {
        if (slice.slice_type === 'heading') {
          return (
            <div key={slice.id}>
              <Heading primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'text') {
          return (
            <div key={slice.id}>
              <Text primary={slice.primary} />
            </div>
          )
        }
      })}
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicThanksPage {
    data {
      meta_description
      page_title
      body {
        ... on PrismicThanksPageDataBodyText {
          id
          primary {
            button1
            heading1
            page_link1 {
              url
              uid
              type
            }
            text1 {
              richText
            }
            tag
          }
          slice_type
        }
        ... on PrismicThanksPageDataBodyHeading {
          id
          slice_type
          primary {
            button1
            heading1
            text1 {
              richText
            }
            page_link1 {
              url
              uid
              type
            }
            parent {
              url
              uid
              type
              link_type
            }
            image1 {
              alt
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}
`

export default ThanksPage
