import React from 'react';
import { Link } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Heading = ({primary}) => {
  return (
    <div className="w-full bg-blue relative">
      <div className="absolute -inset-0 opacity-10">
        <img className="w-full h-full object-cover" src="/img/topography.svg" alt="" />  
      </div>
      <div className="w-full relative">
        <div className="flex-none md:flex justify-between items-center">
          <div className="w-full md:w-6/12 pl-6 md:pl-12 pt-16 pb-16 pr-6 md:pr-24">
            <h1 className="font-brand leading-none font-black text-4xl md:text-5xl text-white">
              {primary.heading1}
            </h1>
            {primary.text1.richText.length ?
            <div className="mt-6 font-sans text-white opacity-75 prose prose-a:text-white prose-a:underline prose-a:hover:text-white prose-xl leading-normal">
              <PrismicRichText linkResolver={linkResolver} field={primary.text1.richText} />
            </div>
            : '' }
            {primary.page_link1.uid ?
              <div className="font-sans font-black text-xl text-blue mt-12 bg-white py-3 px-6 inline-block rounded-lg">
                <Link to={linkResolver(primary.page_link1)}>{primary.button1}</Link>
              </div>
            : '' }
          </div>
          <div className="w-full md:w-6/12">
            {primary.image1 ? <GatsbyImage className="aspect-w-4 aspect-h-3" style={{ width: "100%", height: "100%", display: "block", objectFit: 'cover' }} image={primary.image1.gatsbyImageData} alt={primary.image1.alt ? primary.image1.alt : ''} />
           : ''}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Heading;
